import React from 'react';

import SEO from "components/Seo";
import Header from "components/Header";
import Footer from "components/Footer";

import LayoutMain from "components/layout/layoutMain";
import LayoutPanel from "components/layout/layoutPanel";

import AddressSearchInput from "components/common/AddressSearchInput"
import Link from 'components/common/Link';
import Panel from "components/common/Panel";

import KwikGasInfo from "components/cards/KwikGasInfo";
import LpgOrderInfo from "components/cards/LpgOrderInfo";
import LpgOrderAnytimeInfo from "components/cards/LpgOrderAnytimeInfo";
import RacInfo from "components/cards/RacInfo";
import SignupLpginfo from "components/cards/SignupLpgInfo";

// asset images
// https://www.gatsbyjs.com/docs/how-to/images-and-media/importing-assets-into-files/
import heroBanner from 'images/hero-45kg-local.png';

import testSEOImage from 'images/meta/logo-mycoles.png'; // test


// style
// bespoke styles for this page
// https://www.gatsbyjs.com/docs/how-to/styling/css-modules
import 'styles/pages/lpg.css';

function NaturalGasProductPage() {

	let isNonPrd = process.env.GATSBY_ACTIVE_ENV !== 'prd';
	let seoImage = isNonPrd ? testSEOImage : '';

	return (
		<>
            <SEO title="Residential LPG | Kleenheat" 
            	 description="For over 65 years, Kleenheat has been supplying LPG to thousands of households across WA & NT through a reliable network of local agents and dealers."
            	 image={seoImage} // test
        	/>
            <Header />
            <LayoutMain>

            	{/* hero banner */}
            	<LayoutPanel theme="mer-theme--cyan"
                    		 background="mer-bg--primary-cyan"
                    		 padding="mer-py-lg">
	                <div className="container">
	                    <div className="row mer-panel flex-row-reverse">
	                    	
	                        <div className="col-10 col-sm-7 col-lg-6 mer-text--size-lg">
	                            <div className="content">
	                                <h1>
	                                	Delivering LPG cylinders to more places, more often.
	                                </h1>
	                                <p>Kleenheat delivers to more places, more often across WA and the NT. From Broome to Albany, Perth to Kalgoorlie and Darwin to Alice Springs, we've got you covered.</p>

	                                <div className="mer-panel__actions mer-button-group">
		                           		<Link text="Sign up & order"
			                                  link="/sign-up"
			                                  linkClass="mer-button mer-button--primary" />
	                                    <Link text="View offers"
			                                  link="#offer"
			                                  linkClass="mer-button mer-button--secondary" />
					                </div>
	                            </div>
	                        </div>

	                        <div className="mer-panel__images col-8 col-sm-5 col-lg-4">
	                            <img src={heroBanner} 
	                                 className="mer-panel__image hero-banner"
	                                 alt="45kg local" />
	                        </div>
	                    </div>
	                </div>
	            </LayoutPanel>

	        	<LayoutPanel theme="mer-theme--light mer-bg--ui-light"
	        				 padding="mer-pt-ju mer-pb-de">
	                <div className="container">
	                    <div className="row mer-panel">
	                    	
	                        <div className="col-10 col-sm-8 col-lg-6 mer-text--size-lg text-center">
	                            <div className="content">
	                                <h1 className="mer-color--primary-cyan">
	                                	Order gas bottles online.
	                                </h1>
	                                <p>
	                                	When it comes to all of your cooking, heating and hot water needs, LPG cylinders are convenient, efficient and a clean source of energy. We offer reliable, friendly service and over 65 years of local experience.
	                                </p>

	                                <div className="mer-panel__actions mer-button-group justify-content-center">
		                           		<Link text="Order online"
			                                  link="/lpg/order-online"
			                                  linkClass="mer-button mer-button--primary" />
	                                    <Link text="Sign up & order"
			                                  link="/lpg/sign-up"
			                                  linkClass="mer-button mer-button--secondary" />
					                </div>
	                            </div>
	                        </div>
	                    </div>
	                </div>
	            </LayoutPanel>

		        <LpgOrderInfo  panelBackground="mer-bg--ui-light"
	            		  	   panelPadding="mer-py-de" />

	            <RacInfo  panelBackground="mer-bg--ui-light"
	            		  panelPadding="mer-py-de"
            			  lpgAvailable={true} />

            	<LpgOrderAnytimeInfo  panelBackground="mer-bg--ui-light"
	            		  		   	  panelPadding="mer-pt-de mer-pb-ju" />

	            <SignupLpginfo id="offer" />

	            <KwikGasInfo />
	            		  		   
		    </LayoutMain>
			<Footer />
		</>
	)
}

export default NaturalGasProductPage;