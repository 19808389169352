import React from 'react';
import LayoutPanel from 'components/layout/layoutPanel';
import { Svg } from 'kh-common-components';

function LpgOrderAnytimeInfo (props) {

  return (
    <LayoutPanel background={props.panelBackground}
                 padding={props.panelPadding} 
                 {...props}>
      <div className="container">
        <div className="row mer-panel">
          <div className="mer-panel__images col-5 col-lg-2 col-sm-3 mb-0 mr-lg-5">

              <Svg title="Flowmoji tablet"
                   col="mer-panel__image"
                   icon="flowmoji-tablet"
              /> 
          </div>
          <div className="content col-10 col-xl-5 col-md-6 col-sm-7 offset-sm-1 offset-lg-0">
              <h3>Order online anytime, anywhere</h3>
              <p>Enjoy the ease and convenience of 24/7 online ordering, at a time and place that suits you.</p>
          </div>
        </div>
      </div>
    </LayoutPanel>
  )
}

export default LpgOrderAnytimeInfo;